import { Calculator } from "./components/poonmix";
import { Pagination } from "./metros/pagination";
import { Compare } from "./metros/compare";
import { Product } from "./metros/product";
import { Search } from "./metros/searchBar";
import { UI } from "./metros/ui";
import { Filters } from "./metros/filters";

import $ from "jquery";
require("jquery-ui");

import { Navigator } from "./components/navigations.js";
import { NavLeft } from "./components/sidebars.js";

let filterDOM = [];
let strenght = [];

document.addEventListener('DOMContentLoaded', () => {
    const ui = new UI();
    const filter = new Filters();
    const nav = new NavLeft;
    const paging = new Pagination();
    const product = new Product();
    const se = new Search();
    const cp = new Compare();
    const footer = new Navigator();
    const calculator = new Calculator();
    nav.metro()
    nav.pump().then(nav.getPump())
    nav.strenght()

    const filters = {
        category_id: null,
        prod_strenght: null,
        concrete_type_id: null,
    };

    product.getProduct().then((mixes)=>{
        product.showProducts(mixes).then(()=>{
            ui.toggleCart();
            ui.quote();
            ui.checkout();
            document.querySelector('#category-filter').addEventListener('change', (e) => {
                filters.category_id = parseInt(e.target.value);
                product.applyFiltersAndDisplay(mixes, filters);
            });
            
            document.querySelector('#strength-filter').addEventListener('change', (e) => {
                filters.prod_strenght = parseInt(e.target.value);
                product.applyFiltersAndDisplay(mixes, filters);
            });
    
            document.querySelector('#type-filter').addEventListener('change',(e)=>{
                filters.concrete_type_id = parseInt(e.target.value);
                product.applyFiltersAndDisplay(mixes,filters)
            })
        });
        ui.logicUI();
        product.getPage();
        
        filter.truckFilter(mixes);
        filter.strengthFilter(mixes);
        filter.typeFilter(mixes);
        
        
    })
        
        
    footer.footer()
    ui.showCart();
    ui.cartValue();
    ui.CartQTY();
    ui.checkout();
    se.getSearch();
    cp.compareLogic();
    calculator.calculateLogic();
    
});