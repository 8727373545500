class Compare {
    getSelectedIds() {
        return $('.boxs .prod-id').map(function() { return $(this).text(); }).toArray();
    }
    updateLinkAndCounter() {
        let ids = this.getSelectedIds().map(function(x, i) { return ['', x].join(''); });
        $('#compare .card-body .card-text >button > a').attr('href', '/metros/compares/' + ids.join('/vs/'));
        $(".compareTotal").text(ids.length == 1 ? 'ตรวจสอบ 1/2 รายการ.' : 'ทั้งหมด ' + ids.length + '/2 รายการ.');
        $("#compare").addClass(ids.length <= 0 ? 'hide' : '');

    }
    addCompare(event) {
        let id = $(event.target).val();
        let info = $(event.target).parent().parent().prev().find('.order');
        let title = $(info).attr('ref');
        let image = $(info).attr('img');
        let selected = this.getSelectedIds();
        if (selected.length == 2) return; // already 4 items added
        if (selected.indexOf(id) != -1) return; // item already added

        $('<div/>', { 'id': 'box-' + id, 'class': 'boxs row mb-2' })
            .append($('<span/>', { class: 'prod-id hide', text: id }))
            .append($('<div/>', { class: 'prod-img col-md-2' })
            .append($('<img/>', { src:image ,class:'img-fluid'})))
            .append($('<div/>', { class: 'prod-desc col-md-8', text: title }))
            .append($('<a/>', { href: '#', 'data-id': id, html: '<i class="fas fa-trash-alt"></i>',class:'col-md-2' }))
            .appendTo('#compare .card-body');

        this.updateLinkAndCounter();
        $("#compare").removeClass("hide");
    }
    updateCompare(event) {
        $(event.target).parent().parent().remove();
        this.updateLinkAndCounter();
    }
    compareLogic() {
        let compareItems = []
        $("#product-wrapper").on('change', '.compare', event => {
            if (event.target.checked) {
                this.addCompare(event);
                compareItems.push(event.target.value);
                this.updateLinkAndCounter();
            } else {
                let id = $(event.target).val();
                this.removeItemCompare(id)
                this.updateLinkAndCounter();
            }
        });
        $("#compare").on("click", ".boxs a", event => {
            let id = this.getSingleCheckbox(event);
            this.unCheck(id);
            this.updateCompare(event);
        });
    }
    unCheck(id) {
        $('#compare-' + id).prop('checked', false);
        
    }
    getSingleCheckbox(event) {
        let id = $(event.target).parent().attr('data-id');
        return id;
    }
    removeItemCompare(id) {
        let box = $('#compare .boxs').children().hasClass("prod-id");
        if (box) {
            $('#box-' + id).remove();
        }
    }
}

export {Compare}